import React, { Fragment, useEffect, useLayoutEffect, useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { useSpring, useSprings, animated } from 'react-spring'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Content from "../components/content"
import Nav from "../components/nav"
import StyledA from "../components/styleda"
import Responsive from "../components/responsive"

import ClaytipLogo from "./img/claytip-logo.svg"
import ClaytipCircle from "./img/circle.svg"

const IndexTitle = styled.h2`
  font-family: 'Arvo', serif;
  font-size: 70px;
  @media (max-width: 800px) {
    font-size: 60px;
    margin-top: 30px;
  }
  font-weight: 700;
  margin-bottom: 10px;
`;


const IndexSubtitle = styled.h2`
  font-family: 'Arvo', serif;
  font-size: 40px;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
`;

const ContentBlock = styled.div`
  display: flex;
  padding-bottom: 50px;
  border-bottom: 10px solid #fff;
  width: 100vw;
  max-width: 100%;
`

const ContentPane = styled.div`
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
`;

const ClayGreen = styled.span`
  color: #38E782;
`;

const ClayPurple = styled.span`
  color: #769CFF;
`;

const ClayRed = styled.span`
  color: #FF6A6A;
`;

const ClayYellow = styled.span`
  color: #EFF24A;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.8em;
`;

const HalfCard = styled.div`
  grid-column: span 2/span 2;
  padding: 1em;
  background: linear-gradient(126.68deg, #3A81C2 -1.84%, rgba(0, 128, 97, 0.37) 108.43%);
  border-radius: 1.5em;

  h2 {
    font-size: 1.25em;
    font-weight: 700;
    margin-top: 10px;
  }

  @media (max-width: 900px) {
    grid-column: span 4/span 4;
  }
`;

const QuarterCard = styled.div`
  grid-column: span 1/span 1;
  padding: 1em;
  background: linear-gradient(126.68deg, #3A81C2 -1.84%, rgba(0, 128, 97, 0.37) 108.43%);
  border-radius: 1.5em;

  h2 {
    font-size: 1.25em;
    font-weight: 700;
    margin-top: 10px;
  }

  @media (max-width: 900px) {
    grid-column: span 2/span 2;
  }
`;

const FullCard = styled.div`
  grid-column: span 4/span 4;
  padding: 1em;
  background: linear-gradient(126.68deg, #3A81C2 -1.84%, rgba(0, 128, 97, 0.37) 108.43%);
  border-radius: 1.5em;

  h2 {
    font-size: 1.25em;
    font-weight: 700;
    margin-top: 10px;
  }
`;

const BoldItalic = styled.strong`
  font-weight: 700;
  font-style: italic;
`;

const CircleHalf = styled.div`
  flex: 1;

  @media (max-width: 1020px) {
    display: none;
  }

  @keyframes auth-rotate {
    from {
      transform: rotate(0deg)
    }

    to {
      transform: rotate(360deg)
    }
  }

  .authorization-text {
    fill: #fff;
    font-size: 15px;
    transform-origin: 270.5px 239.5px;
    animation-name: auth-rotate;
    animation-timing-function: linear;
    animation-duration: 30s;
    animation-iteration-count: infinite;
  }
`;

const FullCardSplit = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 50%;
  }

  .mobile-only {
    display: none !important;
  }

  @media (max-width: 900px) {
    .mobile-only {
      display: block !important;
    }

    .non-mobile-only {
      display: none !important;
    }

    & > div {
      width: 100%;
    }
  }
`;

const Positions = {
  graphql: {
    cx: 26 + 72/2,
    cy: 82 + 72/2
  },
  deno: {
    cx: 76 + 72/2,
    cy: 386 + 72/2,
  },
  rest: {
    cx: 122 + 72/2,
    cy: 24 + 72/2,
  },
  s3: {
    cx: 443 + 75/2,
    cy: 72 + 82/2
  },
  stripe: {
    cx: 440 + 72/2,
    cy: 346 + 72/2,
  },
  mailchimp: {
    cx: 326 + 72/2,
    cy: 431 + 72/2,
  },
  postgres: {
    cx: 354 + 72/2,
    cy: 0 + 74/2,
  },
  wasm: {
    cx: 0 + 72/2,
    cy: 277 + 72/2,
  },
  models: {
    cx: 208.5,
    cy: 179.5
  },
  services: {
    cx: 330,
    cy: 304
  }
};

const animations = [
  [
    [ 'graphql', 'models', 'postgres', 'models', 'graphql' ],
    [ 'hidden', 'models', 'deno', 'models', 'hidden' ],
  ],
  [
    [ 'rest', 'models', 'postgres', 'models', 'hidden', 'hidden', 'hidden' ],
    [ 'hidden', 'hidden', 'hidden', 'models', 'wasm', 'hidden', 'hidden' ],
    [ 'hidden', 'hidden', 'hidden', 'hidden', 'wasm', 'services', 'mailchimp' ],
  ],
  [
    [ 'stripe', 'services', 'models', 'deno', 'models', 'postgres' ],
    [ 'hidden', 'hidden', 'hidden', 'deno', 'models', 's3' ]
  ]
];

const animCaptions = [
  "combine calendar events from Postgres with a weather forecast query run in Deno",
  "format a reminder email with WebAssembly and send it with Mailchimp",
  "log Stripe transactions to the database and save the Deno-formatted receipt in S3"
];

const CircleAnim = () => {
  const circleRef = React.useRef(null);
  const [circleWrap, setCircle] = React.useState(null);
  useLayoutEffect(() => {
    setCircle({ circle: circleRef.current.children[0] });
  }, []);

  const [movieIndex, setMovieIndex] = React.useState(animations.length - 1);

  const circle = circleWrap ? circleWrap.circle : null;

  const [animIndex, setAnimIndex] = React.useState(0);
  const positions = useSprings(animations[movieIndex].length, animations[movieIndex].map(c => {
    let target = c[animIndex];
    let wasHidden = false;
    if (target === "hidden" || target === "fade") {
      wasHidden = true;
      target = c.find((x, i) => i > animIndex && x !== "hidden" && x !== "fade");
      if (!target) {
        target = c.slice().reverse().find(x => x !== "hidden" && x !== "fade");
      }
    }

    return {
      cx: Positions[target].cx,
      cy: Positions[target].cy,
      immediate: animIndex === 0,
    };
  }));

  const opacities = useSprings(animations[movieIndex].length, animations[movieIndex].map(c => {
    let prevHidden = false;
    let prevPrevHidden = false;
    let target = c[animIndex];
    if (animIndex > 0 && c[animIndex - 1] === "hidden") {
      prevHidden = true;
    }

    if (animIndex > 1 && c[animIndex - 2] === "hidden") {
      prevPrevHidden = true;
    }

    return {
      opacity: ((animIndex == animations[movieIndex][0].length - 1) || (target === "hidden" || target === "fade" || prevHidden)) ? '0%' : '100%',
      immediate: prevPrevHidden || (target === "hidden" || prevHidden),
    };
  }));

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setAnimIndex(animIndex => (animIndex + 1) % animations[movieIndex][0].length);
    }, 1200);

    return () => clearInterval(interval);
  }, [movieIndex]);

  const [flipper, setFlipper] = useState(false);

  useEffect(() => {
    if (animIndex == 0) {
      setMovieIndex(i => (i + 1) % animations.length);
      setFlipper(f => !flipper);
    }
  }, [animIndex]);

  return (
    <div ref={circleRef}>
      <ClaytipCircle style={{
        width: "90%",
        height: "auto",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        outline: "none"
      }}></ClaytipCircle>
      {circle ? ReactDOM.createPortal(
        (
          positions.map((position, i) => (
            <animated.circle key={i} style={{...position, ...opacities[i], filter: "drop-shadow(4px 6px 3px rgb(0 0 0 / 0.4))"}} r="10" fill="#EFF24A"></animated.circle>
          ))
        ),
        circle
      ) : null}
      <div style={{ position: "relative", marginLeft: "calc(52.5% - 250px)", height: "90px" }}>
        <p style={{
          position: "absolute",
          top: "0",
          width: "500px",
          fontWeight: "400",
          textAlign: "center",
          fontSize: "0.8em",
          color: "#EFF24A",
          opacity: flipper ? "100%" : "0%",
          transition: "opacity 0.5s ease-in-out",
        }}>{animCaptions[flipper ? movieIndex : (movieIndex - 1 + animCaptions.length) % animCaptions.length]}</p>
        <p style={{
          position: "absolute",
          top: "0",
          width: "500px",
          fontWeight: "400",
          textAlign: "center",
          fontSize: "0.8em",
          color: "#EFF24A",
          opacity: flipper ? "0%" : "100%",
          transition: "opacity 0.5s ease-in-out",
        }}>{animCaptions[flipper ? (movieIndex - 1 + animCaptions.length) % animCaptions.length: movieIndex]}</p>
      </div>
    </div>
  );
}

const Index = () => {
  return (
    <div style={{
      overflowX: "hidden"
    }}>
      <Nav current="about"/>
      <Content style={{
        marginLeft: "0px",
        marginRight: "0px",
        width: "100vw",
        maxWidth: "100%"
      }}>
        <ContentBlock style={{
          minHeight: "min(calc(100vh - 200px), 900px)",
          marginTop: "50px"
        }}>
          <ContentPane>
            <div style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
              <div style={{ flex: "1" }}>
                <ClaytipLogo style={{
                  maxWidth: "95%",
                  width: "auto"
                }}></ClaytipLogo>
                <h1 style={{
                  fontSize: "2.5em",
                  fontWeight: 700,
                  lineHeight: "1.3em",
                  marginBlock: "10px"
                }}>build <ClayGreen>fast</ClayGreen>, <ClayPurple>flexible</ClayPurple>, <ClayRed>secure</ClayRed> backends in <ClayYellow>minutes</ClayYellow></h1>
                <OutboundLink href="https://8nnyx61rmmy.typeform.com/to/Zv3LJeV0" style={{
                  color: "#fff",
                  textDecoration: "none",
                  fontWeight: "300",
                  fontSize: "1.2em",
                }}>sign up for the private beta →</OutboundLink>
              </div>
              <CircleHalf>
                <div style={{
                  width: "100%",
                  paddingLeft: "30px",
                  boxSizing: "border-box"
                }}>
                  <CircleAnim/>
                </div>
              </CircleHalf>
            </div>
          </ContentPane>
        </ContentBlock>
        <ContentBlock>
          <ContentPane>
            <h1 style={{
              fontSize: "2.75em",
              marginBottom: "0px"
            }}>It all starts with <ClayGreen>Clay</ClayGreen></h1>
            <p style={{
              fontWeight: "600",
              color: "#CECDCD",
              fontSize: "1.1em",
              marginTop: "10px"
            }}>
              Claytip backends are written in <ClayGreen>Clay</ClayGreen> — a type-safe, declarative language that makes it easy to model your application. Once your model is set up, just build it with the Clay CLI and you have a production-ready GraphQL and REST server that can be exposed directly to clients. And with familiar APIs that simplify complex database concepts, you’ll be up and running in no time.
            </p>
            <CardGrid>
              <FullCard>
                <FullCardSplit>
                  <div style={{ boxSizing: "border-box", paddingRight: "0.5em" }}>
                    <h2>Just a text file</h2>
                    <div className="mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <img style={{
                        width: "100%",
                        boxSizing: "border-box",
                        border: "1em solid #272c35",
                        borderRadius: "1em"
                      }} src="/img/simple-model-clay.png"></img>
                    </div>
                    <p>Clay files are <BoldItalic>regular text files</BoldItalic> that can be opened in your favorite text editor and are easily diffable by Git. There’s no need for a special tool for code review. Everything works with your regular flow.</p>
                    <p>The Clay language comes with all the features you'd expect: modules and imports, rich expressions, and precise types. No matter what language you're coming from, you'll feel right at home.</p>
                  </div>
                  <div className="non-mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img style={{
                      width: "100%",
                      boxSizing: "border-box",
                      border: "1em solid #272c35",
                      borderRadius: "1em"
                    }} src="/img/simple-model-clay.png"></img>
                  </div>
                </FullCardSplit>
              </FullCard>
              <HalfCard>
                <h2>A solid core</h2>
                <p>Claytip automatically scales from serverless deployment to edge servers with intelligent scheduling and optimized query paths.</p>
                <p>Claytip backends compile down to <BoldItalic>efficient and scalable Rust</BoldItalic> that’s ready to deploy anywhere. No need to worry about buffer overflow bugs or heavy memory usage, Claytip has it covered.</p>
              </HalfCard>
              <HalfCard>
                <h2>Bring your own DB</h2>
                <p>Claytip backends can run against <BoldItalic>existing, live databases</BoldItalic> so that you don't have to start from scratch.</p>
                <p>If you're starting afresh, Claytip automatically maintains a <BoldItalic>human-readable schema</BoldItalic> that's compatible with your favorite data tools. Claytip even generates constraints that automatically clean up left over data.</p>
              </HalfCard>
              <FullCard>
                <FullCardSplit>
                  <div style={{ boxSizing: "border-box", paddingRight: "0.5em" }}>
                    <h2>Tooling designed for humans</h2>
                    <div className="mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <img style={{
                        width: "100%",
                        boxSizing: "border-box",
                        border: "1em solid #272c35",
                        borderRadius: "1em"
                      }} src="/img/type-errors.png"></img>
                    </div>
                    <p>The Clay CLI is always ready to help with <BoldItalic>detailed diagnostics</BoldItalic> that point out what went wrong with the <BoldItalic>context</BoldItalic> needed to fix it.</p>
                    <p>Clay even comes with <BoldItalic>editor integrations</BoldItalic> so you get warnings and errors right in your favorite IDE.</p>
                  </div>
                  <div className="non-mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img style={{
                      width: "100%",
                      boxSizing: "border-box",
                      border: "1em solid #272c35",
                      borderRadius: "1em"
                    }} src="/img/type-errors.png"></img>
                  </div>
                </FullCardSplit>
              </FullCard>
            </CardGrid>
          </ContentPane>
        </ContentBlock>
        <ContentBlock>
          <ContentPane>
            <h1 style={{
              fontSize: "2.75em",
              marginBottom: "0px"
            }}>Toss in some <ClayPurple>services</ClayPurple></h1>
            <p style={{
              fontWeight: "600",
              color: "#CECDCD",
              fontSize: "1.1em",
              marginTop: "10px"
            }}>
              Third-party APIs are first-class citizens in Claytip. Just like models, services have their own <ClayPurple>type-safe interfaces</ClayPurple> that make sure you're safely passing data between the database and APIs. Plus, Claytip makes it easy to <ClayPurple>hook into data events</ClayPurple> for auditing, sending emails, and more.
            </p>
            <CardGrid>
              <FullCard>
                <FullCardSplit>
                  <div style={{ boxSizing: "border-box", paddingRight: "0.5em" }}>
                    <h2>Baked into the language</h2>
                      <div className="mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img style={{
                          width: "100%",
                          boxSizing: "border-box",
                          border: "1em solid #272c35",
                          borderRadius: "1em"
                        }} src="/img/login-service-clay.png"></img>
                      </div>
                      <p>Adding services is as simple as defining an interface in a Clay file and connecting to an implementation.</p>
                      <p>Just like models, services can have their own authorization rules, can connect to other models in the database, and can be exposed in the GraphQL layer.</p>
                    </div>
                    <div className="non-mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <img style={{
                        width: "100%",
                        boxSizing: "border-box",
                        border: "1em solid #272c35",
                        borderRadius: "1em"
                      }} src="/img/login-service-clay.png"></img>
                  </div>
                </FullCardSplit>
              </FullCard>
              <FullCard>
                <FullCardSplit>
                  <div style={{ boxSizing: "border-box", paddingRight: "0.5em" }}>
                    <h2>JS and TS built right in</h2>
                    <div className="mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <img style={{
                        width: "100%",
                        boxSizing: "border-box",
                        border: "1em solid #272c35",
                        borderRadius: "1em"
                      }} src="/img/login-service-ts.png"></img>
                    </div>
                    <p>Claytip comes with a built-in execution system for JavaScript and TypeScript services, powered by the <BoldItalic>open-source Deno engine</BoldItalic>.</p>
                    <p>Need to use a custom algorithm for password hashing or talk to an API? Just write some JS and import it as a service in your Clay files.</p>
                  </div>
                  <div className="non-mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img style={{
                      width: "100%",
                      boxSizing: "border-box",
                      border: "1em solid #272c35",
                      borderRadius: "1em"
                    }} src="/img/login-service-ts.png"></img>
                  </div>
                </FullCardSplit>
              </FullCard>
            </CardGrid>
          </ContentPane>
        </ContentBlock>
        <ContentBlock>
          <ContentPane>
            <h1 style={{
              fontSize: "2.75em",
              marginBottom: "0px"
            }}>Add your <ClayRed>security model</ClayRed></h1>
            <p style={{
              fontWeight: "600",
              color: "#CECDCD",
              fontSize: "1.1em",
              marginTop: "10px"
            }}>
              Claytip backends are <ClayRed>secure by design</ClayRed> — no need to worry about SQL injection — and <ClayRed>private by default</ClayRed> — so nobody sees data they're not supposed to. Claytip security is baked into the language with authorization rules, ranging from broad rules across models to fine-grained rules for specific actions.
            </p>
            <CardGrid>
            <FullCard>
                <FullCardSplit>
                  <div style={{ boxSizing: "border-box", paddingRight: "0.5em" }}>
                    <h2>Secure to the core</h2>
                    <div className="mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <img style={{
                        width: "100%",
                        boxSizing: "border-box",
                        border: "1em solid #272c35",
                        borderRadius: "1em"
                      }} src="/img/auth-clay.png"></img>
                    </div>
                    <p>All data in your models is <BoldItalic>private by default</BoldItalic>. To make data available to the right users, simply add authorization rules that specify who should have access.</p>
                    <p>Rules have a <BoldItalic>rich expression language</BoldItalic> that scales from simple role checks to complex queries like checking if a document has been shared. And <BoldItalic>context models</BoldItalic> let you flexibly store session data without bloating your API.</p>
                  </div>
                  <div className="non-mobile-only" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img style={{
                      width: "100%",
                      boxSizing: "border-box",
                      border: "1em solid #272c35",
                      borderRadius: "1em"
                    }} src="/img/auth-clay.png"></img>
                  </div>
                </FullCardSplit>
              </FullCard>
              <HalfCard>
                <h2>Efficiently private</h2>
                <p>Authorization rules are automatically compiled down to <BoldItalic>efficient SQL</BoldItalic> that is sent directly to the database. So queries have lower latency and send minimal data across the wire.</p>
              </HalfCard>
              <HalfCard>
                <h2>Authentication built in</h2>
                <p>Claytip comes ready with logic for <BoldItalic>common authentication patterns</BoldItalic> like using OAuth to issue JWT tokens. So you can get started right away without digging through docs.</p>
              </HalfCard>
            </CardGrid>
          </ContentPane>
        </ContentBlock>
        <ContentBlock>
          <ContentPane>
            <h1 style={{
              fontSize: "2.75em",
              marginBottom: "0px"
            }}>And ship it to <ClayYellow>production</ClayYellow></h1>
            <p style={{
              fontWeight: "600",
              color: "#CECDCD",
              fontSize: "1.1em",
              marginTop: "10px"
            }}>
              Once you're ready to ship your backend, use the Claytip CLI to compile your app down to a <ClayYellow>optimized, memory-efficient native binary</ClayYellow> that carries just the code needed to serve your API.
            </p>
            <CardGrid>
              <HalfCard>
                <h2>Blazing fast</h2>
                <p>Production Claytip servers are <BoldItalic>built with Rust</BoldItalic> so they use minimal memory and scale to high request rates.</p>
                <p>At build time, the Clay CLI automatically generates an <BoldItalic>optimized binary representation</BoldItalic> of your data model that the server can efficiently process.</p>
              </HalfCard>
              <HalfCard>
                <h2>Serverless-ready</h2>
                <p>Claytip is <BoldItalic>perfect for serverless</BoldItalic>, with tiny binary sizes and optimized logic for the most popular platforms.</p>
                <p>Clay backends are meticulously optimized and start in <BoldItalic>less than a millisecond</BoldItalic>, so you wont't pay any costs for boot-up time.</p>
              </HalfCard>
            </CardGrid>
          </ContentPane>
        </ContentBlock>
        <ContentBlock style={{
          paddingTop: "50px"
        }}>
          <ContentPane style={{
            textAlign: "center"
          }}>
            <h1 style={{
              fontSize: "2.5em",
              marginTop: "0px",
              marginBottom: "0px"
            }}>want to learn more?</h1>
            <OutboundLink href="https://8nnyx61rmmy.typeform.com/to/Zv3LJeV0" style={{
              color: "#fff",
              textDecoration: "none",
              fontWeight: "300",
              fontSize: "1.2em",
            }}>sign up for the private beta →</OutboundLink>
          </ContentPane>
        </ContentBlock>
      </Content>
      <footer style={{
        paddingBottom: "40px",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontFamily: "'Poppins', sans-serif",
        color: "#CECDCD",
        textAlign: "center"
      }}>
        <p>Copyright © 2021 Paya Labs Inc. All rights reserved. | <a href="https://payalabs.com/privacypolicy" style={{ color: "inherit" }}>Privacy Policy</a></p>

        <p style={{
          fontSize: "0.8em"
        }}>The Stripe name and logos are trademarks or service marks of Stripe, Inc. or its affiliates in the U.S. and other countries. Other names may be trademarks of their respective owners.</p>
      </footer>
    </div>
  )
};

export default Index;
